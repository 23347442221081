import(/* webpackMode: "eager" */ "/codebuild/output/src252900078/src/frontend/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src252900078/src/frontend/components/AuthorizationProvider.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/codebuild/output/src252900078/src/frontend/components/themeProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["AuthContextProvider"] */ "/codebuild/output/src252900078/src/frontend/context/AuthContext.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src252900078/src/frontend/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src252900078/src/frontend/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src252900078/src/frontend/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src252900078/src/frontend/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src252900078/src/frontend/node_modules/next/font/local/target.css?{\"path\":\"app/layout.js\",\"import\":\"\",\"arguments\":[{\"src\":\"../public/fonts/codecpro.ttf\",\"display\":\"swap\",\"variable\":\"--codecpro\"}],\"variableName\":\"codec_pro\"}");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src252900078/src/frontend/node_modules/next/font/google/target.css?{\"path\":\"app/layout.js\",\"import\":\"Roboto\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":\"400\"}],\"variableName\":\"roboto\"}");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src252900078/src/frontend/node_modules/next/font/local/target.css?{\"path\":\"app/layout.js\",\"import\":\"\",\"arguments\":[{\"src\":\"../public/fonts/neuemachina-regular.otf\",\"display\":\"swap\"}],\"variableName\":\"font2\"}");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src252900078/src/frontend/node_modules/sonner/dist/index.mjs");
